function App() {}

App.init = function() {
	this.bindEvents();
	this.setItemWidth();
};

App.bindEvents = function() {
	this.handleClick('[data-navigate-back]', this.navigateBack);
};

App.handleResize = function() {
	this.setItemWidth();
};

App.setItemWidth = function() {
	$('section.games ul li').attr('style', '');

	// Get lowest item width
	var itemWidth = false;
	var itemCounter = 0;

	$('section.games ul li').each(function() {
		if(!$(this).closest('ul').hasClass('flashContent')) {
			itemCounter++;

			if(($(this).width() < itemWidth && $(this).width() > 0) || !itemWidth) {
				itemWidth = $(this).width();

				// Hack for Firefox and iOS >= 9
				itemWidth = itemWidth - 1;
			}
		}
	});

	
	$('section.games ul li').css('width', itemWidth);

	if(itemCounter > 1) {
		var bigItemWidth = itemWidth*2;
		$('section.games ul li:first-child').css('width', bigItemWidth);
	}
};

App.navigateBack = function() {
	if (document.referrer || document.referrer.indexOf('//' + document.location.host) > -1) {
		return !!history.back();
	}
};

App.handleClick = function(selector, eventHandler) {
	// http://stackoverflow.com/questions/13396297/windows-phone-8-touch-support
	if (window.navigator.msPointerEnabled) {
		$(document).on('MSPointerDown', selector, eventHandler);
	} else {
		$(document).on(!detection.is.touch ? "click" : "touchstart", selector, eventHandler);
	}
};

App.replaceTeaser = function() {
	var imageSizeMap = {};

	// Find next bigger image for small teasers
	function findImageForSize(width) {
		if (typeof imageSizeMap[width] != 'undefined') {
			return imageSizeMap[width];
		}

		var imageSizes = [
			32, 64, 96, 128, 160, 192, 224, 256, 288
		];
		var imageSize;

		for(var i = 0; i < imageSizes.length; i++) {
			if(imageSizes[i] >= width) {
				imageSize = imageSizes[i];
				imageSizeMap[width] = imageSize;
				return imageSize;
			}
		}
		
		return imageSizeMap[width] = width;
	}

	$('img[data-original]').each(function() {
		var $this = $(this),
			width = $this.parent().innerWidth(),
			newImageSize = findImageForSize(width),
			newImg = '',
			cdnPath = 'https://img.cdn.famobi.com/portal/html5games/images/tmp/';

		if (width > 0) {
			newImg = cdnPath;
			newImg += newImageSize + '/';
			newImg += $this.attr('data-original').split('/').pop();
			$this.attr('data-original', newImg).lazyload({
				threshold: Math.max(200, Math.min(400, parseInt(window.innerHeight / 2))),
				effect: 'fadeIn'
			});
		}
	});
}

$(window).ready(function() {
	App.init();
	App.replaceTeaser();

	$(window).resize(function() {
		App.handleResize();
	});
});
